* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

body {
	background: #f5f8ff;
}

.image-previewer-modal {
	.ant-modal-content {
		z-index: 3;
		background-color: transparent !important;
		box-shadow: none !important;
		width: 100%;
		margin-top: -50px;
		.anticon svg {
			color: #f5f8ff;
			font-size: 25px;
		}
		.image-style{
			height: 600px;
			width: 100%;
			object-fit: contain;
		}
		.slick-prev {
			left: -40px;
		}
		.slick-next {
			right: -40px;
		}
		.slick-dots li button:before {
			font-size: 12px;
			line-height: 50px;
		}
	}
	.ant-modal-close {
		display: none;
	}
}
.ant-notification-notice-with-icon,
.ant-message-custom-content {
	display: flex;
	align-items: center;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
	padding-top: 4px;
}
